import areasExpertisePT from "./../library/areas-expertise-pt";
import areasExpertiseEN from "./../library/areas-expertise-en";
import countiesMZ from "./../library/counties-mz";
import countriesPT from "./../library/countries-pt";
import nationalitiesPT from "./../library/nationalities-pt";
import nationalitiesEN from "./../library/nationalities-en";
import positionsPT from "./../library/positions-pt";
import positionsEN from "./../library/positions-en";
// import trainingAreasEN from "./../library/training-areas-en"
// import trainingAreasPT from "./../library/training-areas-pt"
import publishingLangEN from "./../library/publishing-lang-en";
import publishingLangPT from "./../library/publishing-lang-pt";
import { levelAcademicOptionsPT } from "./../library/publishing-lang-pt";
import { levelAcademicOptionsEN } from "./../library/publishing-lang-en";
import genderPT from "./../library/gender_pt";
import genderEN from "./../library/gender_en";
import courseEN from "./../library/training-course-en";
import coursePT from "./../library/training-course-pt";
import provincePT from "./../library/province-pt";
import provinceEN from "./../library/province-en";

export const library = {
  namespaced: true,
  state: {
    areasExpertisePT,
    countiesMZ,
    countriesPT,
    nationalitiesPT,
    nationalitiesEN,
    positionsPT,
    positionsEN,
    publishingLangEN,
    publishingLangPT,
    trainingAreas: [],
    trainingAreasEN: [],
    trainingAreasPT: [],
    areasExpertiseEN,
    genderPT,
    genderEN,
    courseEN,
    coursePT,
    levelAcademicOptionsPT,
    levelAcademicOptionsEN,
    provincePT,
    provinceEN,
  },
  actions: {
    setTrainingArea({ commit }, trainingArea) {
      commit("setTrainingArea", trainingArea);
    },
    setTrainingAreas({ commit }, trainingAreas) {
      commit("setTrainingAreas", trainingAreas);
    },
    updateTrainingArea({ commit }, trainingArea) {
      commit("updateTrainingArea", trainingArea);
    },
    removeTrainingArea({ commit }, id) {
      commit("removeTrainingArea", id);
    },
  },
  mutations: {
    setTrainingArea(state, trainingArea) {
      state.trainingAreasEN.push({
        text: trainingArea.text.en,
        key: trainingArea.key,
      });
      state.trainingAreasPT.push({
        text: trainingArea.text.pt,
        key: trainingArea.key,
      });
      state.trainingAreas.unshift(trainingArea);
    },
    updateTrainingArea(state, trainingArea) {
      const index = state.trainingAreas.findIndex(
        (t) => t.id == trainingArea.id
      );
      state.trainingAreasPT.splice(index, 1, {
        text: trainingArea.text.en,
        key: trainingArea.key,
      });
      state.trainingAreasEN.splice(index, 1, {
        text: trainingArea.text.pt,
        key: trainingArea.key,
      });
      state.trainingAreas.splice(index, 1, trainingArea);
    },
    setTrainingAreas(state, trainingAreas) {
      // console.log({ trainingAreas });
      state.trainingAreasEN = trainingAreas.map((area) => ({
        text: area.text.en,
        key: area.key,
      }));
      state.trainingAreasEN.push({ text: "All", key: "all" });
      state.trainingAreasPT = trainingAreas.map((area) => ({
        text: area.text.pt,
        key: area.key,
      }));
      state.trainingAreasPT.push({ text: "Todas", key: "all" });
      state.trainingAreas = trainingAreas;
    },
    removeTrainingArea(state, id) {
      const index = state.trainingAreas.findIndex((t) => t.id == id);
      console.log(index, "---", id);
      state.trainingAreas.splice(index, 1);
      state.trainingAreasEN.splice(index, 1);
      state.trainingAreasPT.splice(index, 1);
    },
  },
  getters: {
    areasExpertise: (state) => (locale) => {
      switch (locale) {
        case "pt":
          return state.areasExpertisePT;
        case "en":
          return state.areasExpertiseEN;
      }
    },
    countiesMZ: (state) => {
      return state.countiesMZ;
    },
    countries: (state) => (locale) => {
      switch (locale) {
        case "pt":
          return state.countriesPT;
      }
    },
    nationalities: (state) => (locale) => {
      switch (locale) {
        case "pt":
          return state.nationalitiesPT;
        case "en":
          return state.nationalitiesEN;
      }
    },
    provinces: (state) => (locale) => {
      switch (locale) {
        case "pt":
          return state.provincePT;
        case "en":
          return state.provinceEN;
      }
    },
    getLevelAcademic: (state) => (locale) => {
      switch (locale) {
        case "pt":
          return state.levelAcademicOptionsPT;
        case "en":
          return state.levelAcademicOptionsEN;
      }
    },
    positions: (state) => (locale) => {
      switch (locale) {
        case "en":
          return state.positionsEN;
        case "pt":
          return state.positionsPT;
      }
    },
    publishingLang: (state) => (locale) => {
      switch (locale) {
        case "en":
          return state.publishingLangEN;
        case "pt":
          return state.publishingLangPT;
      }
    },
    trainingAreas: (state) => (locale) => {
      switch (locale) {
        case "en":
          return state.trainingAreasEN;
        case "pt":
          return state.trainingAreasPT;
        default:
          return state.trainingAreas;
      }
    },
    gender: (state) => (locale) => {
      switch (locale) {
        case "en":
          return state.genderEN;
        case "pt":
          return state.genderPT;
      }
    },
    trainingCourses: (state) => (locale) => {
      switch (locale) {
        case "en":
          return state.courseEN;
        case "pt":
          return state.coursePT;
      }
    },
  },
};
