import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import dotenv from "dotenv";
import { GC_AUTH_TOKEN } from "./../constants/settings";

dotenv.config();

export const API_URL_KENMARE = "https://kenmare-api.whirelab.com";
// export const API_URL_KENMARE = "https://kenmare-api-demo.whirelab.com";
// export const API_URL_KENMARE = 'http://localhost:3001'

export const API_URL_KENMARE_2 = "wss://kenmare-api.whirelab.com";
// export const API_URL_KENMARE_2 = "wss://kenmare-api-demo.whirelab.com";
// export const API_URL_KENMARE_2 = 'ws://localhost:3001'

const httpLink = createUploadLink({
  uri: API_URL_KENMARE,
});

const wsLink = new WebSocketLink({
  uri: API_URL_KENMARE_2,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(GC_AUTH_TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });

  return forward(operation);
});

export const apolloClientKenmare = new ApolloClient({
  link: authMiddleware.concat(link),
  cache: new InMemoryCache({ addTypename: false }),
  connectToDevTools: true,
});
