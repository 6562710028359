export const vacancy = {
  namespaced: true,
  state: {
    notes: [],
    vacancies: [],
  },
  mutations: {
    addNote (state, payload) {
      const { candidateId, note } = payload
      const index = state.vacancy.candidacies.findIndex(c => c.candidate.id == candidateId)
      state.vacancy.candidacies[index].notes.unshift(note)
    },
    removeVacancy (state, id) {
      const index = state.vacancies.findIndex(v => v.id == id)
      state.vacancies.splice(index, 1)
    },
    setVacancy (state, vacancies) {
      state.vacancies.unshift(vacancies)
    },
    setVacancyScrolled (state, vacancies) {
      state.vacancies.push(...vacancies)
    },
    setVacancies (state, vacancies) {
      state.vacancies = vacancies
    },
    updateVacancy (state, vacancy) {
      const index = state.vacancies.findIndex(v => v.id == vacancy.id)
      state.vacancies.splice(index, 1, vacancy)
    },
    updateVacancyCandidacy (state, { candidacy, vacancyId  }) {
      const index = state.vacancies.findIndex(v => v.id == vacancyId)
      const candidacyIndex = state.vacancies[index].candidacies.findIndex(c => c.candidate.id == candidacy.candidate.id)
      if (candidacyIndex > -1) {
        state.vacancies[index].candidacies[candidacyIndex].appliedFor = true
      } else {
        state.vacancies[index].candidacies.push(candidacy)
      }
    }
  },
  actions: {
    addNote ({ commit }, payload) {
      commit('addNote', payload)
    },
    setVacancy ({ commit }, vacancy) {
      commit('setVacancy', vacancy)
    },
    setVacancies ({ commit }, vacancies) {
      commit('setVacancies', vacancies)
    },
    removeVacancy({ commit }, id) {
      commit('removeVacancy', id)
    },
    updateVacancy({ commit }, vacancy) {
      commit('updateVacancy', vacancy)
    },
    updateVacancyCandidacy({ commit }, payload) {
      commit('updateVacancyCandidacy', payload)
    }
  },
  getters: {
    getVacancies: (state) => {
      return state.vacancies.map(vacancy => ({
        ...vacancy, candidacies: vacancy.candidacies
          ? vacancy.candidacies.filter(candidacy => candidacy.appliedFor )
          : []
      }))
    },
    getVacancyById: (state) => (id) => {
      return state.vacancies.find(v => v.id == id)
    }
  },
}