const Interview = () => import("../views/Interview.vue");

export default [
  {
    path: "candidate/interviews",
    component: Interview,
    meta: { requiresAuth: true },
    name: "candidate.interview",
  },
];
