import dashboardRoute from './../modules/dashboard/router'
import interviewRoute from './../modules/interview/router'
import profileRoute from './../modules/profile/router'
import testRoute from './../modules/test/router'
//const CV = () => import('./../views/CV.vue')
const Dashboard = () => import('./../views/Dashboard.vue')
const ImportedLogin = () => import('./../views/ImportedLogin.vue')
const Register = () => import('./../views/Register.vue')

export default [
  {
    path: '/candidate/',
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      ...dashboardRoute,
      ...interviewRoute,
      ...profileRoute,
      ...testRoute,
    ]
  },
  {
    path: '/candidate/login',
    component: ImportedLogin,
    name: 'candidate.login'
  },
  {
    path: '/candidate/register',
    component: Register,
    name: 'candidate.register'
  }
]