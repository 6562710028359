const Entity = () => import('../views/Entity.vue')
const Finance = () => import('../views/Finance.vue')

export default [
  {
    path: 'entities',
    component: Entity,
    meta: { requiresAuth: true },
    name: 'entities'
  },
  {
    path: 'entities/finances/:id',
    component: Finance,
    meta: { requiresAuth: true },
    name: 'entities.finances',
    props: true
  }
]