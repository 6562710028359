module.exports = [
  { text: "Agricultural Administration", key: "area_expertise_1" },
  { text: "Service Administration", key: "area_expertise_2" },
  { text: "Government Administration", key: "area_expertise_3" },
  { text: "Agriculture", key: "area_expertise_4" },
  { text: "Food and drinks", key: "area_expertise_5" },
  { text: "Animation", key: "area_expertise_6" },
  { text: "Storage", key: "area_expertise_7" },
  { text: "Architecture and Planning", key: "area_expertise_8" },
  { text: "Fundraising", key: "area_expertise_9" },
  { text: "Performing arts", key: "area_expertise_10" },
  { text: "Arts and Crafts", key: "area_expertise_11" },
  { text: "Wholesale", key: "area_expertise_12" },
  { text: "Customer Service", key: "area_expertise_13" },
  { text: "Medical and hospital care", key: "area_expertise_14" },
  { text: "Psychiatric medical care", key: "area_expertise_15" },
  { text: "Parliamentary activities", key: "area_expertise_16" },
  { text: "Industrial automation", key: "area_expertise_17" },
  { text: "Aviation and Aerospace", key: "area_expertise_18" },
  { text: "Banks", key: "area_expertise_19" },
  { text: "Investment Banks", key: "area_expertise_20" },
  { text: "Fine Arts", key: "area_expertise_21" },
  { text: "Consumer goods", key: "area_expertise_22" },
  { text: "Libraries", key: "area_expertise_23" },
  { text: "Biotechnology", key: "area_expertise_24" },
  {
    text: "Venture capital and private equity",
    key: "area_expertise_25",
  },
  { text: "Cinema and Movies", key: "area_expertise_26" },
  {
    text: "International Trade and Development",
    key: "area_expertise_27",
  },
  { text: "Composition and proofreading", key: "area_expertise_28" },
  { text: "Construction", key: "area_expertise_29" },
  { text: "Railroad construction", key: "area_expertise_30" },
  { text: "Shipbuilding", key: "area_expertise_31" },
  { text: "Management Consulting", key: "area_expertise_32" },
  { text: "Accounting", key: "area_expertise_33" },
  { text: "Cosmetics", key: "area_expertise_34" },
  { text: "Defense and Space", key: "area_expertise_35" },
  { text: "Program Development", key: "area_expertise_36" },
  { text: "Design", key: "area_expertise_37" },
  { text: "Graphic design", key: "area_expertise_38" },
  { text: "Medical Devices", key: "area_expertise_39" },
  { text: "Publishing", key: "area_expertise_40" },
  { text: "Distance Education", key: "area_expertise_41" },
  { text: "Packaging and containers", key: "area_expertise_42" },
  { text: "Civil Engineering", key: "area_expertise_43" },
  { text: "Mechanical or industrial engineering", key: "area_expertise_44" },
  { text: "Elementary/high school", key: "area_expertise_45" },
  { text: "University education", key: "area_expertise_46" },
  { text: "Delivery of orders and freight", key: "area_expertise_47" },
  { text: "Training", key: "area_expertise_48" },
  { text: "Sports", key: "area_expertise_49" },
  { text: "Law Enforcement", key: "area_expertise_50" },
  { text: "Philanthropy", key: "area_expertise_51" },
  { text: "Photography", key: "area_expertise_52" },
  { text: "Presidential Office", key: "area_expertise_53" },
  { text: "Investment Management", key: "area_expertise_54" },
  {
    text: "Non-profit Organization Management",
    key: "area_expertise_55",
  },
  { text: "Educational management", key: "area_expertise_56" },
  { text: "Hardware", key: "area_expertise_57" },
  { text: "Hospitality", key: "area_expertise_58" },
  { text: "Real Estate", key: "area_expertise_59" },
  { text: "Commercial real estate", key: "area_expertise_60" },
  { text: "Import and Export", key: "area_expertise_61" },
  { text: "Print", key: "area_expertise_62" },
  { text: "Automotive industry", key: "area_expertise_63" },
  { text: "Pharmaceutical industry", key: "area_expertise_64" },
  { text: "Chemical industry", key: "area_expertise_65" },
  { text: "Textile industry", key: "area_expertise_66" },
  { text: "Recreation facilities and services", key: "area_expertise_67" },
  { text: "Religious Institutions", key: "area_expertise_68" },
  { text: "Internet", key: "area_expertise_69" },
  { text: "Gambling", key: "area_expertise_70" },
  { text: "Computer games", key: "area_expertise_71" },
  { text: "Newspapers", key: "area_expertise_72" },
  { text: "Judiciary", key: "area_expertise_73" },
  { text: "Dairy Products", key: "area_expertise_74" },
  { text: "Leisure, Travel and Tourism", key: "area_expertise_75" },
  { text: "Airlines/Aviation", key: "area_expertise_76" },
  { text: "Logistics and supply chain", key: "area_expertise_77" },
  { text: "Electronics manufacturing", key: "area_expertise_78" },
  { text: "Machinery", key: "area_expertise_79" },
  { text: "Marketing and Advertising", key: "area_expertise_80" },
  { text: "Construction Materials", key: "area_expertise_81" },
  { text: "Sporting Goods", key: "area_expertise_82" },
  { text: "Alternative Medicine", key: "area_expertise_83" },
  { text: "Capital Markets", key: "area_expertise_84" },
  { text: "Broadcast media", key: "area_expertise_85" },
  { text: "Online media", key: "area_expertise_86" },
  { text: "Military", key: "area_expertise_87" },
  { text: "Mining and Metals", key: "area_expertise_88" },
  { text: "Furniture", key: "area_expertise_89" },
  { text: "Museums and Institutions", key: "area_expertise_90" },
  { text: "Song", key: "area_expertise_91" },
  { text: "Nanotechnology", key: "area_expertise_92" },
  { text: "Civic and Social Organization", key: "area_expertise_93" },
  { text: "Political Organization", key: "area_expertise_94" },
  { text: "Research and Guidance Organizations", key: "area_expertise_95" },
  { text: "Paper and forest products", key: "area_expertise_96" },
  { text: "Fishing", key: "area_expertise_97" },
  { text: "Search", key: "area_expertise_98" },
  { text: "Market research", key: "area_expertise_99" },
  { text: "Oil and Energy", key: "area_expertise_100" },
  { text: "Plastic", key: "area_expertise_101" },
  { text: "Public policy", key: "area_expertise_102" },
  { text: "Legal Practice", key: "area_expertise_103" },
  { text: "Medical Practice", key: "area_expertise_104" },
  { text: "Media Production", key: "area_expertise_105" },
  { text: "Food products", key: "area_expertise_106" },
  { text: "Luxury products and jewelry", key: "area_expertise_107" },
  { text: "Electronic products", key: "area_expertise_108" },
  { text: "Recruitment and selection", key: "area_expertise_109" },
  { text: "Human Resources", key: "area_expertise_110" },
  { text: "Renewable resources and environment", key: "area_expertise_111" },
  { text: "Wireless network", key: "area_expertise_112" },
  { text: "Networks", key: "area_expertise_113" },
  { text: "Government Relations", key: "area_expertise_114" },
  { text: "International relations", key: "area_expertise_115" },
  { text: "Public relations and communications", key: "area_expertise_116" },
  { text: "Alternative Dispute Resolution", key: "area_expertise_117" },
  { text: "Restaurants", key: "area_expertise_118" },
  { text: "Clothing and fashion", key: "area_expertise_119" },
  { text: "Health, wellness and physical education", key: "area_expertise_120" },
  { text: "Network and computer security", key: "area_expertise_121" },
  { text: "Security and investigations", key: "area_expertise_122" },
  { text: "Public security", key: "area_expertise_123" },
  { text: "Insurance", key: "area_expertise_124" },
  { text: "Semiconductors", key: "area_expertise_125" },
  { text: "Environmental services", key: "area_expertise_126" },
  { text: "Information services", key: "area_expertise_127" },
  { text: "Financial services", key: "area_expertise_128" },
  { text: "Individual and family services", key: "area_expertise_129" },
  { text: "Juridic services", key: "area_expertise_130" },
  { text: "Event Services", key: "area_expertise_131" },
  { text: "Serviços públicos", key: "area_expertise_132" },
  { text: "Software", key: "area_expertise_133" },
  { text: "Supermarkets", key: "area_expertise_134" },
  {
    text: "Commercial Supplies and Equipment",
    key: "area_expertise_135",
  },
  { text: "Tobacco", key: "area_expertise_136" },
  { text: "information technology and services", key: "area_expertise_137" },
  { text: "Telecommunications", key: "area_expertise_138" },
  { text: "Outsourcing and offshoring", key: "area_expertise_139" },
  { text: "Translation and localization", key: "area_expertise_140" },
  { text: "Maritime Transport", key: "area_expertise_141" },
  { text: "Transport/Trucks/Trains", key: "area_expertise_142" },
  {
    text: "Professional training and guidance",
    key: "area_expertise_143",
  },
  { text: "Retail", key: "area_expertise_144" },
  { text: "Veterinary", key: "area_expertise_145" },
  { text: "Glass, Ceramics and Concrete", key: "area_expertise_146" },
  { text: "Wines and Spirits", key: "area_expertise_147" },
];