module.exports = [
  "Angoche",
  "Beira",
  "Boane",
  "Chibuto",
  "Manjacaze",
  "Massinga",
  "Nhamayábué",
  "Alto Molócuè",
  "Cabo Delgado",
  'Catandica',
  "Chimoio",
  "Chiúre",
  "Chókwè",
  "Cuamba",
  "Dondo",
  "Gaza",
  'Gondola',
  "Gorongosa",
  "Gurúè",
  "Ilha de Moçambique",
  "Inhambane",
  "Lichinga",
  "Macia",
  "Maganja da Costa",
  'Malema',
  'Mandimba',
  'Manhiça',
  "Manica",
  "Maputo (cidade)",
  'Marromeu',
  'Marrupa',
  "Matola",
  "Matola",
  "Maxixe",
  "Metangula",
  "Milange",
  'Moatize',
  "Mocímboa da Praia",
  "Mocuba",
  "Monapo",
  "Montepuez",
  "Mueda e Pemba",
  "Nacala Porto",
  "Namaacha",
  "Nampula",
  "Nhamatanda",
  'Niassa',
  "Pemba",
  "Praia do Bilene",
  "Quelimane",
  "Quissico",
  "Ribaué",
  "Sofala",
  "Sussundenga",
  "Tete",
  "Ulongué",
  "Vilanculos",
  "Xai-Xai",
  'Zambézia'
]