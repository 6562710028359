import Vue from "vue";
import App from "./App.vue";
import FlagIcon from "vue-flag-icon";
import JsonExcel from "vue-json-excel";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import IconifyIcon from "@iconify/vue";
import VueMask from "v-mask";
import apolloProvider from "./api";
import i18n from "./plugins/i18n";
import VueObserveVisibility from "vue-observe-visibility";
import "./plugins/vue-currency-input";
import "./plugins/v-currency-field";
import "@/plugins/vuelidate";
import "@/plugins/vue-croppa";
import "@/plugins/vue-social-sharing";
import "./plugins/vuetify-money.js";
import { GC_USER_ID } from "./constants/settings";
// import VuePeerJS from "vue-peerjs";
// import Peer from "peerjs";

Vue.config.productionTip = false;

window.Fire = new Vue();
Vue.component("downloadExcel", JsonExcel);
Vue.use(FlagIcon);
Vue.use(VueMask);
Vue.use(VueObserveVisibility);
Vue.use(IconifyIcon);
// Vue.use(VuePeerJS, new Peer({}));
Vue.component("Video", {
  template: "<video></video>",
});

let userId = localStorage.getItem(GC_USER_ID);

new Vue({
  apolloProvider,
  router,
  store,
  vuetify,
  data: {
    userId,
  },
  i18n,
  render: (h) => h(App),
}).$mount("#app");
