module.exports = [{
  text: "Maputo City",
  key: 1
},
{
  key: "CMaputo_1",
  text: "Urban District of KaMpfumo"
},
{
  key: "CMaputo_2",
  text: "Urban District of Nlhamankulu"
},
{
  key: "CMaputo_3",
  text: "Urban District of KaMaxaquene"
},
{
  key: "CMaputo_4",
  text: "Urban District of KaMavota"
},
{
  key: "CMaputo_5",
  text: "Urban District of KaMubukwana"
},
{
  key: "CMaputo_6",
  text: "Municipal District of KaTembe"
},
{
  key: "CMaputo_8",
  text: "Municipal District of KaNyaka"
},
{
  text: "Maputo Province",
  key: 2
},

{
  key: "Maputo_1",
  text: "Boane"
},
{
  key: "Maputo_2",
  text: "Magude"
},
{
  key: "Maputo_3",
  text: "Manhiça"
},
{
  key: "Maputo_4",
  text: "Marracuene"
},
{
  key: "Maputo_5",
  text: "Matola"
},
{
  key: "Maputo_6",
  text: "Matutuíne"
},
{
  key: "Maputo_7",
  text: "Moamba "
},
{
  key: "Maputo_8",
  text: "Namaacha"
},

{
  text: "Gaza Province",
  key: 3
},
{
  key: "Gaza_1",
  text: "Bilene"
},
{
  key: "Gaza_2",
  text: "Chibuto"
},
{
  key: "Gaza_3",
  text: "Chicualacuala"
},
{
  key: "Gaza_4",
  text: "Chigubo"
},
{
  key: "Gaza_5",
  text: "Chókwè"
},
{
  key: "Gaza_6",
  text: "Chongoene"
},
{
  key: "Gaza_7",
  text: "Guijá"
},
{
  key: "Gaza_8",
  text: "Limpopo"
},
{
  key: "Gaza_9",
  text: "Mabalane"
},
{
  key: "Gaza_10",
  text: "Manjacaze"
},
{
  key: "Gaza_11",
  text: "Mapai"
},
{
  key: "Gaza_12",
  text: "Massangena"
},
{
  key: "Gaza_13",
  text: "Massingir"
},
{
  key: "Gaza_14",
  text: "Xai-Xai"
},
{
  text: "Inhambane Province",
  key: 4
},
{
  key: "Inhambane_1",
  text: "Funhalouro"
},
{
  key: "Inhambane_2",
  text: "Govuro"
},
{
  key: "Inhambane_3",
  text: "Homoíne"
},
{
  key: "Inhambane_4",
  text: "Inhambane"
},
{
  key: "Inhambane_5",
  text: "Inharrime"
},
{
  key: "Inhambane_6",
  text: "Inhassoro"
},
{
  key: "Inhambane_7",
  text: "Jangamo"
},
{
  key: "Inhambane_8",
  text: "Mabote"
},
{
  key: "Inhambane_9",
  text: "Massinga"
},
{
  key: "Inhambane_10",
  text: "Maxixe"
},
{
  key: "Inhambane_11",
  text: "Morrumbene"
},
{
  key: "Inhambane_12",
  text: "Panda"
},
{
  key: "Inhambane_13",
  text: "Vilanculos "
},
{
  key: "Inhambane_14",
  text: "Zavala"
},


{
  text: "Manica Province",
  key: 5
},

{
  key: "Manica_1",
  text: "Bárue"
},
{
  key: "Manica_2",
  text: "Chimoio"
},
{
  key: "Manica_3",
  text: "Gondola"
},
{
  key: "Manica_4",
  text: "Guro"
},
{
  key: "Manica_5",
  text: "Macate"
},
{
  key: "Manica_6",
  text: "Machaze"
},
{
  key: "Manica_7",
  text: "Macossa"
},
{
  key: "Manica_8",
  text: "Manica"
},
{
  key: "Manica_9",
  text: "Mossurize"
},
{
  key: "Manica_10",
  text: "Sussundenga"
},
{
  key: "Manica_11",
  text: "Tambara"
},
{
  key: "Manica_12",
  text: "Vanduzi"
},

{
  text: "Sofala Province",
  key: 6
},

{
  key: "Sofala_1",
  text: "Beira"
},
{
  key: "Sofala_2",
  text: "Búzi"
},
{
  key: "Sofala_3",
  text: "Caia"
},
{
  key: "Sofala_4",
  text: "Chemba"
},
{
  key: "Sofala_5",
  text: "Cheringoma"
},
{
  key: "Sofala_6",
  text: "Chibabava"
},
{
  key: "Sofala_7",
  text: "Dondo"
},
{
  key: "Sofala_8",
  text: "Gorongosa"
},
{
  key: "Sofala_9",
  text: "Machanga"
},
{
  key: "Sofala_10",
  text: "Maringué"
},
{
  key: "Sofala_12",
  text: "Marromeu"
},
{
  key: "Sofala_13",
  text: "Muanza"
},
{
  key: "Sofala_14",
  text: "Nhamatanda"
},

{
  text: "Tete Province",
  key: 7
},

{
  key: "Tete_1",
  text: "Angónia"
},
{
  key: "Tete_1",
  text: "Cahora-Bassa"
},
{
  key: "Tete_2",
  text: "Changara"
},
{
  key: "Tete_3",
  text: "Chifunde"
},
{
  key: "Tete_4",
  text: "Chiuta"
},
{
  key: "Tete_5",
  text: "Dôa"
},
{
  key: "Tete_6",
  text: "Macanga"
},
{
  key: "Tete_7",
  text: "Magoé"
},
{
  key: "Tete_8",
  text: "Marara"
},
{
  key: "Tete_9",
  text: "Marávia"
},
{
  key: "Tete_10",
  text: "Moatize"
},
{
  key: "Tete_11",
  text: "Mutarara"
},
{
  key: "Tete_12",
  text: "Tete"
},
{
  key: "Tete_13",
  text: "Tsangano"
},
{
  key: "Tete_14",
  text: "Zumbo"
},

{
  text: "Niassa Province",
  key: 8
},
{
  key: "Niassa_0",
  text: "Chimbonila"
},
{
  key: "Niassa_1",
  text: "Cuamba"
},
{
  key: "Niassa_2",
  text: "Lago"
},
{
  key: "Niassa_3",
  text: "Lichinga"
},
{
  key: "Niassa_4",
  text: "Majune"
},
{
  key: "Niassa_5",
  text: "Mandimba"
},
{
  key: "Niassa_6",
  text: "Marrupa"
},
{
  key: "Niassa_7",
  text: "Maúa"
},
{
  key: "Niassa_8",
  text: "Mavago"
},
{
  key: "Niassa_9",
  text: "Mecanhelas"
},
{
  key: "Niassa_10",
  text: "Mecula"
},
{
  key: "Niassa_11",
  text: "Metarica"
},
{
  key: "Niassa_12",
  text: "Muembe"
},
{
  key: "Niassa_13",
  text: "N'gauma"
},
{
  key: "Niassa_14",
  text: "Nipepe "
},
{
  key: "Niassa_15",
  text: "Sanga"
},

{
  text: "Zambézia Province",
  key: 9
},
{
  key: " Zambézia_1",
  text: "Alto Molócue"
},
{
  key: "Zambézia_2",
  text: "Chinde"
},
{
  key: "Zambézia_3",
  text: "Derre"
},
{
  key: "Zambézia_4",
  text: "Gilé"
},
{
  key: "Zambézia_5",
  text: "Gurué"
},
{
  key: "Zambézia_6",
  text: "Ile"
},
{
  key: "Zambézia_7",
  text: "Inhassunge"
},
{
  key: "Zambézia_8",
  text: "Luabo"
},
{
  key: "Zambézia_9",
  text: "Lugela"
},
{
  key: "Zambézia_10",
  text: "Maganja da Costa"
},
{
  key: "Zambézia_11",
  text: "Milange"
},
{
  key: "Zambézia_12",
  text: "Mocuba"
},
{
  key: "Zambézia_13",
  text: "Mocubela"
},
{
  key: "Zambézia_14",
  text: "Molumbo"
},
{
  key: "Zambézia_15",
  text: "Mopeia"
},
{
  key: "Zambézia_16",
  text: "Morrumbala"
},
{
  key: "Zambézia_17",
  text: "Mulevala"
},
{
  key: "Zambézia_18",
  text: "Namacurra"
},
{
  key: "Zambézia_19",
  text: "Namarroi"
},
{
  key: "Zambézia_20",
  text: "Nicoadala"
},
{
  key: "Zambézia_21",
  text: "Pebane"
},
{
  key: "Zambézia_22",
  text: "Quelimane"
},
{
  text: "Nampula Province",
  key: 10
},
{
  key: "Nampula_1",
  text: "Angoche"
},
{
  key: "Nampula_2",
  text: "Eráti"
},
{
  key: "Nampula_3",
  text: "Ilha de key"
},
{
  key: "Nampula_4",
  text: "Lalaua"
},
{
  key: "Nampula_5",
  text: "Larde"
},
{
  key: "Nampula_6",
  text: "Liúpo"
},
{
  key: "Nampula_7",
  text: "Malema"
},
{
  key: "Nampula_8",
  text: "Meconta"
},
{
  key: "Nampula_9",
  text: "Mecubúri"
},
{
  key: "Nampula_10",
  text: "Memba"
},
{
  key: "Nampula_11",
  text: "Mogincual"
},
{
  key: "Nampula_12",
  text: "Mogovolas"
},
{
  key: "Nampula_13",
  text: "Moma"
},
{
  key: "Nampula_14",
  text: "Monapo"
},
{
  key: "Nampula_15",
  text: "Mossuril"
},
{
  key: "Nampula_16",
  text: "Muecate"
},
{
  key: "Nampula_17",
  text: "Murrupula"
},
{
  key: "Nampula_18",
  text: "Nacala-a-Velha"
},
{
  key: "Nampula_19",
  text: "Nacala Porto"
},
{
  key: "Nampula_20",
  text: "Nacarôa"
},
{
  key: "Nampula_21",
  text: "Nampula"
},
{
  key: "Nampula_22",
  text: "Rapale "
},
{
  key: "Nampula_23",
  text: "Ribaué"
},

{
  text: "Cabo Delgado Province",
  key: 11
},

{
  key: "Cabo_Delgado_1",
  text: "Ancuabe"
 },
 {
  key: "Cabo_Delgado_2",
  text: "Balama"
 },
 {
  key: "Cabo_Delgado_3",
  text: "Chiúre"
 },
 {
  key: "Cabo_Delgado_4",
  text: "Ibo"
 },
 {
  key: "Cabo_Delgado_5",
  text: "Macomia"
 },
 {
  key: "Cabo_Delgado_6",
  text: "Mecúfi"
 },
 {
  key: "Cabo_Delgado_7",
  text: "Meluco"
 },
 {
  key: "Cabo_Delgado_8",
  text: "Metuge"
 },
 {
  key: "Cabo_Delgado_9",
  text: "Mocímboa da Praia"
 },
 {
  key: "Cabo_Delgado_10",
  text: "Montepuez"
 },
 {
  key: "Cabo_Delgado_11",
  text: "Mueda"
 },
 {
  key: "Cabo_Delgado_12",
  text: "Muidumbe"
 },
 {
  key: "Cabo_Delgado_13",
  text: "Namuno"
 },
 {
  key: "Cabo_Delgado_14",
  text: "Nangade"
 },
 {
  key: "Cabo_Delgado_15",
  text: "Palma"
 },
 {
  key: "Cabo_Delgado_16",
  text: "Pemba e"
 },
 {
  key: "Cabo_Delgado_17",
  text: "Quissanga"
 },
]