module.exports = {
  academicEducation: "Formação académica",
  availability: "Disponibilidade",
  languages: "Línguas",
  professionalExperience: "Anos de experiência",
  year: "Ano",
  years: "Anos",
  postingForm: "Formulário de publicação",
  toView: "Visualização",
  clienteName: "Nome do cliente",
  typeVacancy: "Tipo de anúncio",
  category: "Categoria",
  jobTitle: "Título da vaga",
  language: "Língua",
  level: "Nivel",
  doc_label:"Documento exigido",
  doc_rule_label:"Categoria",
  academicEdu: "Formação académica",
  yearExperiencie: "Anos de experiência",
  provinceState: "Província/Município",
  enterJobDesc: "Insira a desacrição da vaga",
  startDate: "Data inicial",
  endDate: "Data final",
  tvacancy: ["Emprego", "Estágio"],
  categoryOptions: ["Tempo inteiro", "Parcial", "Freelancer"],
  languageOptions: [
    "Português", 
    "Inglês", 
    "Françês", 
    "Alemão", 
    "Espanhol",
    "Barwe",
    "Chewa",
    "Chiyao",
    "Chopi",
    "Chuwabu",
    "Dema",
    "Kokola",
    "Koti",
    "Kunda",
    "Lolo",
    "Lomwe",
    "Maindo",
    "Makhuwa",
    "Makonde",
    "Makwe",
    "Manyawa",
    "Marenje",
    "Mwani",
    "Nathembo",
    "Ndau",
    "Ngoni",
    "Nsenga",
    "Nyungwe",
    "Phimbi",
    "Ronga",
    "Sena",
    "Shona",
    "Swahili",
    "Swati",
    "Takwane",
    "Tawara",
    "Tewe",
    "Tonga",
    "Tsonga",
    "Tswa",
    "Zulu"
  ],
  documents:[
    { key: "doc_1", text: "Carta de motivação" },
    { key: "doc_2", text: "Currículum Vitae" },
    { key: "doc_3", text: "Certificados" },
    { key: "doc_4", text: "Carta de referência" },
  ],
  doc_rules:[
    { key: "rule_1", text: "Obrigatório" },
    { key: "rule_2", text: "Opcional porém relevante" },
  ],
  levelLanguageOptions: ["Básico", "Intermédio", "Avançado"],
  levelLanguageOptions1: [
    { text: "Básico", key: "basic" },
    { text: "Intermédio", key: "intermediate" },
    { text: "Avançado", key: "advanced" },
  ],
  levelAcademicOptionsPT: [
    { key: "basic", text: "Básico" },
    { key: "elementary", text: "Elementar" },
    { key: "dedium", text: "Médio" },
    { key: "bachelor", text: "Bacharelato" },
    { key: "graduation", text: "Licenciatura" },
    { key: "master_degree", text: "Mestrado" },
    { key: "doctorate", text: "Doutoramento" },
    { key: "Phd", text: "Phd" },
  ],
  levelAcademicOptions: [
    "Básico geral",
    "Médio geral",
    "Médio técnico",
    "Licenciatura",
    "5 Mestrado",
    "6 Phd",
  ],
  requiredField: "Campo obrigatoria",
  finish: "Finalizar",
  publish: "Publicar",
  closeSave: "Salvar como rascunho",
  proceed: "prosseguir",
  alert: "Complete o seu perfil para poder continuar ",
  weight: "Pontuação",
  area: "Áreas afins",
  add_name_stage: "Adicione o nome da etapa. ex: Triagem de currículos",
  what_aspects_intend_analyze:
    "Quais aspectos pretende analisar na etapa acima?",
};
