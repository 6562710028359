import Vue from 'vue'
import VueRouter from 'vue-router'

import authRoutes from '@/modules/auth/router'
import candidateRoutes from '@/modules/candidate/router'
import entityRoutes from '@/modules/entity/router'
import partnerRoutes from '@/modules/partner/router'
import rootRoutes from '@/modules/root/router'

import { GC_AUTH_TOKEN } from '@/constants/settings'
import { apolloClient } from '@/api'
import { USER_QUERY } from './../modules/auth/graphql/user'

Vue.use(VueRouter)

const routes = [
  ...authRoutes,
  ...candidateRoutes,
  ...entityRoutes,
  ...partnerRoutes,
  ...rootRoutes,
  { path: '', redirect: '/login' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    const token = window.localStorage.getItem(GC_AUTH_TOKEN)
    const loginRoute = {
      path: '/login',
      query: { redirect: to.fullPath }
    }
    if (token) {
      try {
        await apolloClient.query({
          query: USER_QUERY,
          fetchPolicy: 'network-only'
        })
        return next()
      } catch (error) {
        const noInternet = window.navigator.onLine ? false : true
        if (noInternet) {
          alert('No Internet Connection')
          return next(false)
        } else {
          console.log('Auto Login Error: ', error)
          return next(loginRoute)
        }
      }
    }
    return next(loginRoute)
  }
  next()
})

export default router
