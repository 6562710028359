module.exports = [
  { text: "Administração de Empresas", key: "area_1" },
  { text: "Administração de Redes e Sistemas", key: "area_2" },
  {
    text:
      "Administração e Gerenciamento de Computação/Tecnologia da Informação",
    key: "area_3",
  },
  { text: "Administração e Gestão de Empresas", key: "area_4" },
  { text: "Administração e Negócios", key: "area_5" },
  { text: "Administração Pública", key: "area_6" },
  { text: "Administração, Negócios e Marketing", key: "area_7" },
  { text: "Administração/Gestão Esportiva", key: "area_8" },
  { text: "Agroeconomia e Extensão Agrária  ", key: "area_9" },
  { text: "Agronomia - Solos e Nutrição de Plantas", key: "area_10" },
  { text: "Alimentos, Nutrição e Saúde", key: "area_11" },
  {
    text:
      "Animação, Tecnologia Interativa, Design Videográfico e Efeitos Especiais",
    key: "area_12",
  },
  { text: "Antropologia", key: "area_13" },
  { text: "Arqueologia e Gestão do Património", key: "area_14" },
  { text: "Arquitectura e Planeamento Físico", key: "area_15" },
  { text: "Arquitetura da Paisagem", key: "area_16" },
  { text: "Arquitetura e Engenharia Naval", key: "area_17" },
  { text: "Arquitetura e Urbanismo", key: "area_18" },
  { text: "Arte e História da Arte", key: "area_19" },
  { text: "Arte Metal e Joalheria", key: "area_20" },
  { text: "Artes Públicas", key: "area_21" },
  { text: "Artes Visuais, Dança e Teatro", key: "area_22" },
  { text: "Audiologia e Patologia da Fala/Linguagem", key: "area_23" },
  { text: "Auxiliar de Medicina Veterinária", key: "area_24" },
  { text: "Auxiliar Jurídico", key: "area_25" },
  { text: "Biblioteconomia", key: "area_26" },
  { text: "Biblioteconomia e Ciência da Informação", key: "area_27" },
  { text: "Bioengenharia e Engenharia Biomédica", key: "area_28" },
  { text: "Bioinformática", key: "area_29" },
  { text: "Biologia - Geral", key: "area_30" },
  { text: "Biologia Aplicada", key: "area_31" },
  { text: "Biologia e Saúde", key: "area_32" },
  { text: "Biologia Humana", key: "area_33" },
  { text: "Biologia Marinha Aquática e Costeira", key: "area_34" },
  { text: "Biologia Marinha e Oceanografia Biológica", key: "area_35" },
  { text: "Biologia Molecular", key: "area_36" },
  { text: "Biologia/Ciências Biológicas - Geral", key: "area_37" },
  { text: "Bioquímica", key: "area_38" },
  { text: "Bioquímica e Biologia Molecular", key: "area_39" },
  { text: "Biotecnologia", key: "area_40" },
  { text: "Botânica/Biologia Vegetal", key: "area_41" },
  { text: "Cartografia e Pesquisa Geológica", key: "area_42" },
  {
    text: "Certificação de Competências de Jovens e Adultos",
    key: "area_43",
  },
  { text: "Ciência da Computação", key: "area_44" },
  { text: "Ciência Política", key: "area_45" },
  { text: "Ciências Ambientais", key: "area_46" },
  { text: "Ciências Biomédicas - Geral", key: "area_47" },
  { text: "Ciências da Computação e Informática", key: "area_48" },
  { text: "Ciências da Comunicação", key: "area_49" },
  { text: "Ciências da Nutrição", key: "area_50" },
  { text: "Ciências da Saúde", key: "area_51" },
  { text: "Ciências de Informação Geográfica", key: "area_52" },
  { text: "Ciências dos Materiais", key: "area_53" },
  { text: "Ciências Físicas", key: "area_54" },
  { text: "Ciências Humanas", key: "area_55" },
  { text: "Ciências Naturais", key: "area_56" },
  { text: "Ciências Políticas", key: "area_57" },
  { text: "Ciências Sociais", key: "area_58" },
  { text: "Ciências Veterinárias", key: "area_59" },
  { text: "Cinema, TV e Vídeo", key: "area_60" },
  { text: "Comportamento Organizacional", key: "area_61" },
  { text: "Comunicação de Massa e Estudos da Mídia", key: "area_62" },
  { text: "Comunicação e Estudos da Mídia", key: "area_63" },
  { text: "Comunicação Organizacional", key: "area_64" },
  { text: "Comunicação Organizacional e Negócio", key: "area_65" },
  { text: "Conservação de Recursos Naturais", key: "area_66" },
  { text: "Contabilidade", key: "area_67" },
  { text: "Contabilidade e Auditoria", key: "area_68" },
  { text: "Contabilidade e Finanças", key: "area_69" },
  { text: "Contabilidade e Negócios", key: "area_70" },
  { text: "Cultural", key: "area_71" },
  { text: "Desenho de Moda", key: "area_72" },
  { text: "Desenho Industrial", key: "area_73" },
  { text: "Desenvolvimento de Recursos Humanos", key: "area_74" },
  { text: "Desenvolvimento e Educação de Infância", key: "area_75" },
  { text: "Desenvolvimento Humano e Estudos da Família", key: "area_76" },
  { text: "Design de Interiores", key: "area_77" },
  { text: "Design e Artes Aplicadas", key: "area_78" },
  { text: "Design e Comunicação Visual", key: "area_79" },
  { text: "Design Gráfico", key: "area_80" },
  { text: "Direito", key: "area_81" },
  { text: "Direito e Estudos Jurídicos", key: "area_82" },
  { text: "Direito e Pesquisa Jurídica", key: "area_83" },
  { text: "Direito Financeiro", key: "area_84" },
  { text: "Direito Penal e Segurança Pública", key: "area_85" },
  {
    text: "Ecologia e Conservação da Biodiversidade Terrestre",
    key: "area_86",
  },
  { text: "Econometria e Economia Quantitativa", key: "area_87" },
  { text: "Economia", key: "area_88" },
  { text: "Economia e Gestão Empresarial", key: "area_89" },
  { text: "Educação Ambiental", key: "area_90" },
  { text: "Enfermagem em UTI", key: "area_91" },
  { text: "Engenharia", key: "area_92" },
  {
    text: "Engenharia Aerospacial, Aeronáutica e Astronáutica",
    key: "area_93",
  },
  { text: "Engenharia Agronómica ", key: "area_94" },
  { text: "Engenharia Ambiental e Gestão de Desastres", key: "area_95" },
  { text: "Engenharia Ambiental e Sanitária", key: "area_96" },
  { text: "Engenharia Biomédica/Médica", key: "area_97" },
  { text: "Engenharia Civil", key: "area_98" },
  { text: "Engenharia de Computação", key: "area_99" },
  { text: "Engenharia de Materiais", key: "area_100" },
  { text: "Engenharia de Sistemas", key: "area_101" },
  { text: "Engenharia de Software", key: "area_102" },
  { text: "Engenharia de Telecomunicações", key: "area_103" },
  { text: "Engenharia do Ambiente", key: "area_104" },
  {
    text:
      "Engenharia e Gestão de Energias Alternativas e Recursos Petrolíferos",
    key: "area_105",
  },
  {
    text:
      "Engenharia e Gestão de Tecnologias de Informação e Comunicação (TIC's)",
    key: "area_106",
  },
  { text: "Engenharia e Gestão Industrial", key: "area_107" },
  { text: "Engenharia Eléctrica", key: "area_108" },
  { text: "Engenharia Electrónica", key: "area_109" },
  { text: "Engenharia Elétrica e Eletrônica", key: "area_110" },
  { text: "Engenharia Física", key: "area_111" },
  { text: "Engenharia Física/Física Aplicada", key: "area_112" },
  { text: "Engenharia Florestal ", key: "area_113" },
  { text: "Engenharia Industrial", key: "area_114" },
  { text: "Engenharia Informática", key: "area_115" },
  { text: "Engenharia Mecânica", key: "area_116" },
  { text: "Engenharia Nuclear", key: "area_117" },
  { text: "Engenharia Química", key: "area_118" },
  { text: "Engenharia Química e Biomolecular", key: "area_119" },
  { text: "Ensino de Francês", key: "area_120" },
  { text: "Ensino de Inglês", key: "area_121" },
  {
    text: "Ensino de Língua, Cultura e Literatura Chinesa",
    key: "area_122",
  },
  { text: "Ensino de Línguas Bantu", key: "area_123" },
  { text: "Ensino de Português", key: "area_124" },
  {
    text: "Especializações em Vendas, Merchandising e Marketing",
    key: "area_125",
  },
  { text: "Estatística", key: "area_126" },
  { text: "Estudos Bíblicos", key: "area_127" },
  { text: "Estudos da Ásia Oriental", key: "area_128" },
  {
    text:
      "Estudos e Arqueologia Clássica, do Mediterrâneo Antigo e do Oriente Médio",
    key: "area_129",
  },
  { text: "Estudos Espanhóis e Ibéricos", key: "area_130" },
  { text: "Estudos Franceses", key: "area_131" },
  { text: "Estudos Gerais", key: "area_132" },
  { text: "Estudos Internacionais", key: "area_133" },
  { text: "Estudos Japoneses", key: "area_134" },
  { text: "Estudos Judaicos", key: "area_135" },
  { text: "Estudos Jurídicos", key: "area_136" },
  { text: "Estudos Norte-Americanos", key: "area_137" },
  { text: "Estudos Russos", key: "area_138" },
  {
    text: "Estudos Russos, Centro-Europeus, do Leste Europeu e da Eurásia",
    key: "area_139",
  },
  { text: "Estudos Ucranianos", key: "area_140" },
  { text: "Estudos Urbanos", key: "area_141" },
  { text: "Farmácia", key: "area_142" },
  { text: "Farmacologia", key: "area_143" },
  { text: "Filosofia", key: "area_144" },
  { text: "Finanças", key: "area_145" },
  { text: "Física", key: "area_146" },
  { text: "Física Teórica e Matemática", key: "area_147" },
  { text: "Físico-Química", key: "area_148" },
  { text: "Fisiologia Vegetal", key: "area_149" },
  { text: "Fisioterapia", key: "area_150" },
  { text: "Fotografia", key: "area_151" },
  { text: "Geografia", key: "area_152" },
  { text: "Geologia Aplicada1", key: "area_153" },
  { text: "Geologia/Ciências da Terra", key: "area_154" },
  { text: "Gerenciamento de Sistemas, Redes e LAN/WAN", key: "area_155" },
  { text: "Gestão", key: "area_156" },
  { text: "Gestão Bancária", key: "area_157" },
  {
    text: "Gestão da Cadeia de Suprimentos e Logística",
    key: "area_158",
  },
  { text: "Gestão de Entidades sem Fim Lucrativo", key: "area_159" },
  { text: "Gestão de Hospitalidade", key: "area_160" },
  { text: "Gestão de Instituições Educacionais", key: "area_161" },
  { text: "Gestão de Marketing", key: "area_162" },
  {
    text: "Gestão de Pessoas com Ênfase em Liderança Organizacional",
    key: "area_163",
  },
  { text: "Gestão de Projetos", key: "area_164" },
  { text: "Gestão de Projetos de TI", key: "area_165" },
  { text: "Gestão de Recursos de Informação", key: "area_166" },
  { text: "Gestão de Recursos Humanos", key: "area_167" },
  { text: "Gestão de Redes e Segurança de Sistemas", key: "area_168" },
  { text: "Gestão de Seguros", key: "area_169" },
  { text: "Gestão de Sistemas de Informação", key: "area_170" },
  { text: "Gestão de Turismo", key: "area_171" },
  { text: "Gestão de Varejo", key: "area_172" },
  { text: "Gestão do Ensino Superior", key: "area_173" },
  { text: "Gestão e Supervisão Operacional", key: "area_174" },
  { text: "Gestão Financeira", key: "area_175" },
  { text: "Gestão Hospitalar", key: "area_176" },
  { text: "Gestão Hoteleira", key: "area_177" },
  { text: "Gestão Hoteleira e de Restaurantes", key: "area_178" },
  { text: "Gestão Imobiliária", key: "area_179" },
  { text: "Gestão Tributária", key: "area_180" },
  { text: "Hidrologia/Ciência dos Recursos Hídricos", key: "area_181" },
  { text: "Higienista Dental", key: "area_182" },
  { text: "História", key: "area_183" },
  { text: "História dos Estados Unidos", key: "area_184" },
  { text: "Idiomas e Literaturas Estrangeiras", key: "area_185" },
  { text: "Informática", key: "area_186" },
  { text: "Instrutor de Yoga", key: "area_187" },
  { text: "Interação Homem-Computador", key: "area_188" },
  { text: "Jornalismo", key: "area_189" },
  { text: "Letras - Escrita Criativa", key: "area_190" },
  {
    text: "Letras - Língua Inglesa e Literaturas de Língua Inglesa",
    key: "area_191",
  },
  { text: "Letras - Literatura", key: "area_192" },
  { text: "Letras – Redação", key: "area_193" },
  { text: "Letras, Estudos Gerais e Ciências Humanas", key: "area_194" },
  { text: "Licenciatura em Educação Especial", key: "area_195" },
  { text: "Língua Alemã e Literaturas de Língua Alemã", key: "area_196" },
  { text: "Língua de Sinais de Moçambique", key: "area_197" },
  {
    text: "Língua Francesa e Literaturas de Língua Francesa",
    key: "area_198",
  },
  {
    text: "Língua Inglesa e Literaturas de Língua Inglesa",
    key: "area_199",
  },
  {
    text: "Língua Japonesa e Literaturas de Língua Japonesa",
    key: "area_200",
  },
  { text: "Língua Khmer e Literaturas de Língua Khmer", key: "area_201" },
  {
    text: "Língua Ucraniana e Literaturas de Língua Ucraniana",
    key: "area_202",
  },
  {
    text: "Língua Urálica e Literaturas de Língua Urálica",
    key: "area_203",
  },
  { text: "Língua Urdu e Literaturas de Língua Urdu", key: "area_204" },
  {
    text:
      "Línguas, Literaturas e Linguística Turca, Uralo-Altaica, Caucasiana e da Ásia Central",
    key: "area_205",
  },
  { text: "Linguística", key: "area_206" },
  { text: "Literatura Americana (Estados Unidos)", key: "area_207" },
  { text: "Literatura Britânica", key: "area_208" },
  { text: "Literatura Infanto-Juvenil", key: "area_209" },
  { text: "Manejo de Recursos Naturais", key: "area_210" },
  { text: "Marketing", key: "area_211" },
  { text: "Marketing Internacional", key: "area_212" },
  { text: "Matemática", key: "area_213" },
  { text: "Matemática Aplicada", key: "area_214" },
  { text: "Matemática e Ciência da Computação", key: "area_215" },
  { text: "Medicina", key: "area_216" },
  { text: "Medicina Dentaria", key: "area_217" },
  { text: "Medicina Geral", key: "area_218" },
  { text: "Medicina Veterinária", key: "area_219" },
  { text: "Meteorologia", key: "area_220" },
  { text: "Microbiologia", key: "area_221" },
  { text: "Museologia, Colecionismo e Curadoria", key: "area_222" },
  { text: "Música", key: "area_223" },
  { text: "Música - Expressão Vocal/Ópera", key: "area_224" },
  { text: "Música - Jazz", key: "area_225" },
  { text: "Negócios Internacionais", key: "area_226" },
  {
    text: "Negócios Internacionais e Comércio Exterior",
    key: "area_227",
  },
  { text: "Neurobiologia e Comportamento", key: "area_228" },
  { text: "Neurociência", key: "area_229" },
  { text: "Nutrição", key: "area_230" },
  { text: "Oceanografia Química e Física", key: "area_231" },
  { text: "Odontologia", key: "area_232" },
  { text: "Oficial de Justiça", key: "area_233" },
  { text: "Operador de Impressora", key: "area_234" },
  { text: "Optometria", key: "area_235" },
  { text: "Oratória e Retórica", key: "area_236" },
  { text: "Organização e Gestão da Educação", key: "area_237" },
  { text: "Orientação Acadêmica", key: "area_238" },
  { text: "Patologia Vegetal/Fitopatologia", key: "area_239" },
  { text: "Pedagogia - Ensino Fundamental", key: "area_240" },
  { text: "Pedagogia - Waldorf/Steiner", key: "area_241" },
  { text: "Pedagogia Musical", key: "area_242" },
  { text: "Pesquisa Jurídica", key: "area_243" },
  { text: "Pesquisa Operacional", key: "area_244" },
  { text: "Planejamento Financeiro", key: "area_245" },
  {
    text: "Planejamento Urbano, Comunitário ou Regional",
    key: "area_246",
  },
  { text: "Políticas de Segurança e Defesa Nacional", key: "area_247" },
  { text: "Preservação e Conservação Histórica", key: "area_248" },
  { text: "Prevenção de Uso de Drogas", key: "area_249" },
  {
    text: "Professor de Cosmetologia, Barbearia e Manicure",
    key: "area_250",
  },
  {
    text: "Programa de Residência Médica - Psiquiatria Infanto-Juvenil",
    key: "area_251",
  },
  { text: "Programa de Residência Médica - Urologia", key: "area_252" },
  {
    text: "Programa de Residência Médica - Urologia Pediátrica",
    key: "area_253",
  },
  { text: "Programação de Computadores", key: "area_254" },
  {
    text: "Programação de Computadores - Aplicativos Específicos",
    key: "area_255",
  },
  { text: "Programação de Jogos Digitais", key: "area_256" },
  { text: "Psicologia", key: "area_257" },
  { text: "Psicologia Clínica", key: "area_258" },
  { text: "Psicologia das organizações", key: "area_259" },
  {
    text: "Psicologia Escolar e de Necessidades Educativas Especiais",
    key: "area_260",
  },
  { text: "Psicologia Industrial e Organizacional", key: "area_261" },
  { text: "Psicologia Social e Comunitária", key: "area_262" },
  { text: "Publicidade", key: "area_263" },
  { text: "Química", key: "area_264" },
  { text: "Química Ambiental", key: "area_265" },
  { text: "Química Analítica", key: "area_266" },
  { text: "Química de Polímeros", key: "area_267" },
  { text: "Química dos Materiais", key: "area_268" },
  { text: "Química Farmacêutica", key: "area_269" },
  { text: "Química Industrial  25  Matemática", key: "area_270" },
  { text: "Química Inorgânica", key: "area_271" },
  { text: "Química Orgânica", key: "area_272" },
  { text: "Quiropraxia", key: "area_273" },
  { text: "Rádio e Televisão", key: "area_274" },
  { text: "Reabilitação Vocacional", key: "area_275" },
  { text: "Redes de Sistemas e Telecomunicações", key: "area_276" },
  { text: "Relações Internacionais", key: "area_277" },
  { text: "Relações Públicas", key: "area_278" },
  { text: "Relações Públicas/Gestão de Imagem", key: "area_279" },
  { text: "Relações Trabalhistas e Sindicais", key: "area_280" },
  { text: "Religião/Estudos Religiosos", key: "area_281" },
  { text: "Relojoeiro e Joalheiro", key: "area_282" },
  { text: "Saúde e Bem-Estar – Geral", key: "area_283" },
  { text: "Saúde e Educação Física", key: "area_284" },
  { text: "Saúde Pública", key: "area_285" },
  {
    text:
      "Segurança Pública, Aplicação da Lei, Bombeiros e Serviços Relacionados",
    key: "area_286",
  },
  { text: "Serviço Social", key: "area_287" },
  { text: "Serviços Humanos", key: "area_288" },
  { text: "Serviços Jurídicos", key: "area_289" },
  { text: "Silvicultura Urbana", key: "area_290" },
  { text: "Sociologia", key: "area_291" },
  { text: "Teatro", key: "area_292" },
  { text: "Técnico de Emergência Médica e UTI", key: "area_293" },
  {
    text: "Técnico em Laboratório de Análises Clínicas",
    key: "area_294",
  },
  { text: "Técnico em Ultrassonografia", key: "area_295" },
  { text: "Tecnologia da Informação", key: "area_296" },
  {
    text: "Tecnologia em Engenharia Elétrica e Eletrônica",
    key: "area_297",
  },
  { text: "Tecnologia em Engenharia Química", key: "area_298" },
  { text: "Tecnologia em Oficina de Usinagem", key: "area_299" },
  { text: "Tecnologia em Processos Químicos", key: "area_300" },
  { text: "Tecnologia em Química", key: "area_301" },
  {
    text: "Tecnologia em Tecnologia da Informação/Sistemas da Informação",
    key: "area_302",
  },
  { text: "Tecnologia em Tecnologia Industrial", key: "area_303" },
  { text: "Tecnologias Aplicadas à Educação", key: "area_304" },
  { text: "Teologia/Estudos Teológicos", key: "area_305" },
  { text: "Terapia Ocupacional", key: "area_306" },
  { text: "Têxtil e Vestuário", key: "area_307" },
  { text: "Vendas, Merchandising e Marketing", key: "area_308" },
  { text: "Veterinária – Zoonoses", key: "area_309" },
  { text: "Viagens", key: "area_310" },
  { text: "Virologia", key: "area_311" },
  { text: "Viticultura e Enologia", key: "area_312" },
  { text: "Webmaster", key: "area_313" },
  { text: "Zeladoria", key: "area_314" },
  { text: "Zoologia/Biologia Animal", key: "area_315" },
  { text: "Zootecnia", key: "area_316" },
  { text: "Todas", key: "all" },
];
