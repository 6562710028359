const Test = () => import('../views/Test.vue')
const Activities = () => import('../views/Activity.vue')
export default [
  {
    path: 'tests',
    component: Test,
    meta: { requiresAuth: true },
    name: 'partner.tests'
  },
  {
    path: 'tests/activities',
    component: Activities,
    meta: { requiresAuth: true },
    name: 'partner.activities'
  },
  
]