// import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
// const modulesL = JSON.parse(localStorage.getItem("modules"));
const modulesL = JSON.parse(localStorage.getItem("modulesL"));

export const auth = {
  namespaced: true,
  state: {
    user: null,
    modules: modulesL ? modulesL : [],
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    updateCandidate(state, candidate) {
      state.user.candidate = candidate;
    },
    updateEntity(state, entity) {
      state.user.entity = entity;
    },
    setModules(state, modules) {
      state.modules = modules;
    },
    addModule(state, module) {
      state.modules.push(module);
      localStorage.setItem("modulesL", JSON.stringify(state.modules));
    },
    updateModule(state, module) {
      const index = state.modules.findIndex((module) => module.id == module.id);
      state.modules.splice(index, 1, module);
      localStorage.setItem("modulesL", JSON.stringify(state.modules));
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    updateCandidate({ commit }, candidate) {
      commit("updateCandidate", candidate);
    },
    updateEntity({ commit }, entity) {
      commit("updateEntity", entity);
    },
    setModules({ commit }, modules) {
      commit("setModules", modules);
    },
    addModule({ commit }, module) {
      commit("addModule", module);
    },
    updateModule({ commit }, module) {
      commit("updateModule", module);
    },
  },
  getters: {
    getModulesExpired: (state) => {
      return state.modules.filter(
        (findedModule) => findedModule && findedModule.expired
      );
    },
    getCurrentUser: (state) => {
      return state.user;
    },
    getCurrentModule: (state) => {
      return state.user.entity.modules[state.user.entity.modules.length - 1];
    },
    getCandidate: (state) => {
      return state.candidate;
    },
    getModules: (state) => {
      return state.modules.filter(
        (findedModule) => findedModule && !findedModule.expired
      );
    },
    getModuleValue: (state) => {
      return state.modules.reduce((accumulator, currentModule) => {
        return accumulator + currentModule.balance;
      }, 0);
    },
  },
};
