const Candidate = () => import('../views/Candidate.vue')
const Profile = () => import('../views/Profile.vue')

export default [
  {
    path: 'entity/candidates',
    component: Candidate,
    meta: { requiresAuth: true },
    name: 'candidates'
  },
  {
    path: 'entity/candidates/profile/:id',
    component: Profile,
    meta: { requiresAuth: true },
    name: 'candidates.profile',
    props: true
  }
]